<template>
    <section class="content">
        <table class="table table-hover" ref="tableuser" id="table-rekap-pembayaran">
            <thead>
                <tr>
                    <th>Nama Siswa</th>
                    <th>Tanggal Daftar</th>
                    <th>Tahun Ajaran</th>
                    <th>No Telp</th>
                    <th>Kelas</th>
                    <th>Total Tagihan</th>
                    <th>Terbayar</th>
                    <th>Kekurangan</th>
                    <th>Status</th>
                    <th>Branch</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ formTitle }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body row">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Nama</label>
                                <input type="hidden" name="row_id" value="" v-model="form.id"/>
                                <input type="hidden" name="filecode" value="" v-model="form.filecode"/>
                                <v-select :options="optRegistrasi" v-model="form.siswa_registrasi_id" :reduce="opt => opt.id" @input="getDataPayment"/>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Nomor</label>
                                <input id="notelp" class="form-control notelp" v-model="form.notelp" type="text" required="required" name="notelp" autocomplete="off" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Total Tagihan</label>
                                <input id="nilai_total" class="form-control nilai_total" v-model="form.nilai_total" type="text" required="required" name="nilai_total" autocomplete="off" readonly />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Terbayar</label>
                                <input id="terbayar" class="form-control terbayar" v-model="form.terbayar" type="text" required="required" name="terbayar" autocomplete="off" readonly />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Kekurangan</label>
                                <input id="kekurangan" class="form-control kekurangan" v-model="form.kekurangan" type="text" required="required" name="kekurangan" autocomplete="off" readonly />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Bayar</label>
                                <input id="bayar" class="form-control bayar" v-model="form.bayar" type="text" required="required" name="bayar" autocomplete="off" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="inputFile">Bukti</label>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input inputfile" id="inputFile"  name="file" @change="uploadFoto(this)">
                                    <label class="custom-file-label" for="inputFile">Choose file</label>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="modal fade" id="modal-riwayat">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Riwayat</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body riwayat">
                        <div v-for="item in dataRiwayat" :key="item.id">
                            <div class="timeline">
                                <div class="time-label">
                                    <span class="bg-blue">{{item.tanggal}}</span>
                                </div>
                                <div>
                                    <div class="timeline-item">
                                        <div class="timeline-body">
                                            Pembayaran #{{item.nomor}} sebesar {{formatRupiah(item.pembayaran ? item.pembayaran : 0)}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /.modal -->
        <div class="cetak" style="width:auto;">
            <div class="invoice p-3 mb-3">
              <!-- title row -->
              <div class="row">
                <div class="col-12">
                  <h4>
                    <i class="fas fa-globe"></i> Taruna Cendekia
                    <small class="float-right"></small>
                  </h4>
                </div>
                <!-- /.col -->
              </div>
              <!-- info row -->
              <div class="row invoice-info" style="margin-bottom: 12px;">
                <div class="col-sm-4 invoice-col">
                  <b class="invoice-nomor" style="font-size:larger;">List Pembayaran</b><br>
                  <br>
                  <table>
                      <tr>
                          <td>Code</td>
                          <td> : </td>
                          <td><span class="invoice-code"></span></td>
                      </tr>
                      <tr>
                          <td>Siswa</td>
                          <td> : </td>
                          <td><span class="invoice-siswa"></span></td>
                      </tr>
                      <tr>
                          <td>Kelas</td>
                          <td> : </td>
                          <td><span class="invoice-kelas"></span></td>
                      </tr>
                  </table>
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->

              <!-- Table row -->
              <div class="row">
                <div class="col-6 table-responsive">
                  <table class="table table-striped">
                        <thead>
                            <tr>
                                <td>Tanggal</td>
                                <td>Nominal</td>
                            </tr>
                        </thead>
                        <tbody class="cetak-item">
                            
                        </tbody>
                        <tfoot>
                            <tr>
                                <th style="width:50%">Subtotal:</th>
                                <td class="invoice-subtotal">Rp 0</td>
                            </tr>
                            <tr>
                                <th>Total:</th>
                                <td class="invoice-total">Rp 0</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
export default {
    name: 'RekapPembayaranSiswa',
    components: {
        vSelect,
    },
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            formTitle: 'Tambah',
            optRegistrasi: [],
            dataRiwayat: [],
            dataCetak: [],
            form: {
                id:'',
                siswa_registrasi_id:'',
                filecode:'',
                nama: '',
                notelp:'',
                kelas:'',
                nilai_total:'',
                terbayar:'',
                kekurangan:'',
                bayar:''
            },
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        console.log('load initial data', this.$route)

        authFetch('/pr/rekap_pembayaran_siswa/registrasi')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optRegistrasi = js.data;
            })
    },
    methods: {
        handleClick(e) {
            if (e.srcElement.getAttribute('class') == 'btn btn-info') {
                this.getRiwayat(e.srcElement.getAttribute('data-id'))
            }
            if (e.srcElement.getAttribute('class') == 'btn btn-warning') {
                this.cetakData(e.srcElement.getAttribute('data-id'))
            }
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
        },
        getDataPayment: function() {
            if (this.form.siswa_registrasi_id) {
                authFetch('/pr/rekap_pembayaran_siswa/get_data_payment/'+this.form.siswa_registrasi_id)
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {
                    var data = this.form;
                    this.form = js.data;
                    this.form.siswa_registrasi_id = data.siswa_registrasi_id;
                })
            }

        },
        submitForm: function(ev) {
            const e = this.$refs;

            if (this.form.kekurangan == '0') {
                alert('Tagihan sudah lunas');
                return false
            }
            
            this.doUpload().then((res) => {
                if (res.status === 201) {

                } else if (res.status === 400) {}

                return res.json();
                
            }).then(js => {
                console.log('result')
                console.log(js)
                this.errors = [];
                if (!js.status) {
                    console.log(js.details)

                    for (var key in js.details) {
                        if (js.details.hasOwnProperty(key)) {
                            this.errors.push(js.details[key])
                        }
                    }

                    return;
                } else {
                    var self = this;
                    const e = this.$refs;
                    self.form.filecode = js.message[0].code;

                    var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
                    var urlSubmit = '/pr/rekap_pembayaran_siswa';
                    if (this.method == 'PUT') urlSubmit = '/pr/rekap_pembayaran_siswa/' + this.form.id;

                    authFetch(urlSubmit, {
                            method: this.method,
                            body: data
                        })
                        .then(res => {
                            if (res.status === 201) {

                            } else if (res.status === 400) {}
                            return res.json();
                        })
                        .then(js => {

                            this.errors = [];
                            if (!js.success) {
                                console.log(js.details)

                                for (var key in js.details) {
                                    if (js.details.hasOwnProperty(key)) {
                                        this.errors.push(js.details[key])
                                    }
                                }

                                return;
                            }
                            this.table.api().ajax.reload();
                            $(e.formDialog).modal('hide');
                        });
                }
            });

            ev.preventDefault();
        },
        uploadFoto : function (file) {
            var file_data = $('#inputFile').prop('files');
            var form_data = new FormData();
            var fileInput = $('#inputFile')[0];
            $.each(fileInput.files, function(k,file){
              form_data.append('file', file);
            });
            form_data.append('subdir', '/laporan-dinas-luar/');
            form_data.append('id', this.form.id);
            this.form_data = form_data
            return false
        },
        doUpload: function() {
            return authFetch('/pr/rekap_pembayaran_siswa/upload_data', {
                method: 'POST',
                body: this.form_data,
                headers: {
                  'Content-Type': null,
                }
            })
        },
        getRiwayat: function(id) {
            var ids = id ? id : 0;
            authFetch('/pr/rekap_pembayaran_siswa/get_riwayat/'+ids)
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {
                    if (js.status) {
                        this.dataRiwayat = js.data;
                    } else {
                        this.dataRiwayat = [];
                    }
                })
        },
        cetakData: function(id) {
            authFetch('/pr/rekap_pembayaran_siswa/get_cetak/'+id)
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {
                    if (js.status) {
                        this.dataCetak = js.data;
                        if (js.data.length > 0) {
                            $('.invoice-code').html(js.data[0].code)
                            $('.invoice-siswa').html(js.data[0].display_name)
                            $('.invoice-kelas').html(js.data[0].kelas)
                        } else {
                            $('.invoice-code').html('')
                            $('.invoice-siswa').html('')
                            $('.invoice-kelas').html('')
                        }
                        let idr = Intl.NumberFormat("en-ID", {
                            style: "currency",
                            currency: "IDR",
                            useGrouping: true,
                            maximumSignificantDigits: 3,
                        });
                        var total = 0;
                        $('.cetak-item').html('');
                        js.data.map(function (item,i) {
                            if (item.pembayaran) {
                                total = total + parseFloat(item.pembayaran)
                            }
                            $('.cetak-item').append(`
                                <tr>
                                    <td>`+item.tanggal+`</td>
                                    <td>`+idr.format(item.pembayaran)+`</td>
                                </tr>
                            `);
                        })

                        $('.invoice-subtotal').html(idr.format(total))
                        $('.invoice-total').html(idr.format(total))
                        // $('.invoice-date').html(js.data.tanggal)
                        // $('.invoice-nomor').html('Invoice #'+js.data.nomor)
                        // $('.invoice-terbilang').html(js.data.terbilang)
                        window.print();
                    } else {
                        this.dataCetak = [];
                    }
                })
        },
        formatRupiah: function(angka, prefix){
            var separator;
            var angka = angka.toString();
            if (angka) {
                angka = angka.split('.');
                angka = angka[0]
            }
            var number_string = angka.replace(/[^,\d]/g, '').toString(),
            split           = number_string.split(','),
            sisa            = split[0].length % 3,
            rupiah          = split[0].substr(0, sisa),
            ribuan          = split[0].substr(sisa).match(/\d{3}/gi);
         
            // tambahkan titik jika yang di input sudah menjadi angka ribuan
            if(ribuan){
                separator = sisa ? '.' : '';
                rupiah += separator + ribuan.join('.');
            }
         
            rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
            return prefix == undefined ? rupiah : (rupiah ? 'IDR ' + rupiah : '');
        }
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tableuser, {
            "title": "List Pembayaran Siswa",
            "roles": this.$route.params.roles,
            "ajax": "/pr/rekap_pembayaran_siswa",
            "columns": [
                { "data": "display_name" },
                { "data": "tanggal_pendaftaran" , sortable: false},
                { "data": "tahun_ajaran" , sortable: false},
                { "data": "phone" },
                { "data": "kelas" },
                { "data": "nilai_total" },
                { "data": "pembayaran" },
                { "data": "nilai_total", sortable: false },
                { "data": "is_pengajuan_angsuran" },
                { "data": "branch" },
                { "data": "status", sortable: false },
            ],
            filterBy: [0, 1, 2, 3, 4, 9],
            scrollX: true,
            "rowCallback": function(row, data) {
                var total_bayar = data.nilai_total - data.pembayaran;
                if (data.is_pengajuan_angsuran == 't') {
                    if (data.status == '3') {
                        if (total_bayar == '0') {
                            $('td:eq(8)', row).html('<span class="badge badge-success" style="font-size:90%">Lunas</span>');
                        } else {
                            $('td:eq(8)', row).html('<span class="badge badge-warning" style="font-size:90%">Belum Lunas</span>');
                        }
                    } else {
                        if (total_bayar == '0') {
                            $('td:eq(8)', row).html('<span class="badge badge-success" style="font-size:90%">Lunas</span>');
                        } else {
                            $('td:eq(8)', row).html('<span class="badge badge-info" style="font-size:90%">Angsuran</span>');
                        }
                    }
                } else {
                    if (total_bayar == '0') {
                        if (data.nilai_total) {
                            $('td:eq(8)', row).html('<span class="badge badge-success" style="font-size:90%">Lunas</span>');
                        } else {
                            $('td:eq(8)', row).html('<span class="badge badge-danger" style="font-size:90%">Data Invalid</span>');
                        }
                    } else {
                        $('td:eq(8)', row).html('<span class="badge badge-warning" style="color:white;font-size:90%">Belum Lunas</span>');
                    }
                }
                let idr = Intl.NumberFormat("en-ID", {
                    style: "currency",
                    currency: "IDR",
                    useGrouping: true,
                    maximumSignificantDigits: 3,
                });
                $('td:eq(5)', row).html(idr.format(data.nilai_total));
                $('td:eq(6)', row).html(idr.format(data.pembayaran));
                $('td:eq(7)', row).html(idr.format(data.nilai_total - data.pembayaran));
                $('td:eq(10)', row).html('<a type="button" class="btn btn-info" data-toggle="modal" data-target="#modal-riwayat" data-id="'+data.siswa_registrasi_id+'">Riwayat</a> | <a type="button" class="btn btn-success" data-toggle="modal" data-target="#modal-riwayat" data-id="'+data.siswa_registrasi_id+'">Update</a> | <a type="button" class="btn btn-warning" data-toggle="modal" data-id="'+data.siswa_registrasi_id+'">Cetak</a> | <a type="button" class="btn btn-primary" data-toggle="modal" data-target="#modal-riwayat" data-id="'+data.siswa_registrasi_id+'">Billing</a>');
            },
            buttonClick: (evt) => {
                if (evt.role == 'create') {
                    self.form = {};
                    self.method = 'POST';
                    self.errors = [];
                    self.formTitle = 'Tambah Pembayaran Siswa';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'update' && evt.data) {
                    self.form = evt.data;
                    self.method = 'PUT';
                    self.errors = [];
                    self.formTitle = 'Edit Pembayaran Siswa';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'delete' && evt.data) {
                    self.form = evt.data;
                    authFetch('/pr/rekap_pembayaran_siswa/' + evt.data.id, {
                            method: 'DELETE',
                            body: 'id=' + evt.data.id
                        })
                        .then(res => {
                            return res.json();
                        })
                        .then(js => {
                            this.table.api().ajax.reload();
                        });
                }

            },
        });

    }
}
</script>
<style type="text/css">
    .cetak {
        display: none;
    }
    .btn {
        color: white;
    }
    a:not([href]):not([tabindex]) {
        color: white;
    }

    @media print {
        #undefined_area {
            display: none;
        }

        .cetak {
            visibility: visible;
            display: initial;
        }

        .borderless td,
        .borderless th {
            border: none !important;
        }

        footer {
            display: none;
        }
    }
</style>